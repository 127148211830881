<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31" :ref="'container'">
      <section class="main-content">
        <div class="section" v-if="notifications">
          <div class="section__titles">
            <h3 class="section__title">Centro de notificaciones</h3>
          </div>
          <!--h5 class="section__title text-alt">Se han encontrado los siguientes ({{total}}) resultados de tipo {{filterType}} para "{{keywords}}"</!--h5-->
          <Loader v-for="index in perPage" :key="index" viewBox="0 0 300 200">
            <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
            <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
            <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
            <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
          </Loader>
          <template v-if="!loading">
            <div class="box box--no-shadow box--notifications">
              <h5 class="caption" v-if="notifications.find(n => $moment.utc(n.createdAt).isSame($moment.utc(), 'day'))"><strong>Hoy</strong></h5>
              <div v-for="notification in notifications" :key="notification.id">
                <p class="caption after" v-if="$moment.utc(notification.createdAt).isBefore($moment.utc(), 'day') && 
                notifications.filter(n => $moment.utc(n.createdAt).isBefore($moment.utc(), 'day')).indexOf(notification) == 0">
                  <strong>Antes de hoy</strong>
                </p>
                <div class="notification">
                  <div :class="['dot', notification.seen ? 'dot--inactive' : '' ]"></div>
                  <p :class="[notification.seen ? 'notification--inactive' : '' ]">
                    <a href="#"
                      class="notification-item"
                      v-html="formatNotification(notification)"
                      @click="markAsRead(notification)"
                      >
                    </a>
                    {{$moment.utc(notification.createdAt).fromNow()}}
                  </p>                  
                </div>
              </div>
            </div>
          </template>
          <!-- <div class="post-list__end" v-if="this.posts.length == this.totalSelected">
            <img src="@/assets/images/illustrations/ill_checklist.png" alt="En la imagen un listado de tareas completado">
          </div> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import { ContentLoader as Loader } from 'vue-content-loader'

export default {
  components: { SideNav, Loader },
  name: 'Home',
  data(){
    return {
      notifications: null,
      oldTotal: 0,
      newTotal: 0,
      checkNotifications: false,
      loading: false,
      page: 0,
      loadingMoreNotifications: false,
      lastId: 0
    }
  },
  created(){
    this.loading = true
    this.reNew()
    this.loading = false
  },
  methods: {
    reNew() {
      this.$axios.post("notification/total").then(res0 => {
        this.oldTotal = res0.data
          if(this.oldTotal != this.newTotal || this.checkNotifications) {
            this.checkNotifications = false
            let previous = null
            this.$axios.post(`notification/getAll/${this.page}/0`).then(res => {
              this.page++
              this.newTotal = res.data.total
              let preFilteredNotifications = []
              const notifications = res.data.notifications
              if(notifications.length > 0){
                preFilteredNotifications.push(notifications[0])
                previous = notifications[0]
                notifications.shift()
                notifications.forEach((e) => {
                  previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
                  previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
                  if(previous.route == e.route && previous.route_id == e.route_id && previous.type == e.type) {
                    if(!(previous.from.includes(e.from))){
                      previous.from.push(e.from)
                      previous.id.push(e.id)
                    }
                  }
                  else
                    preFilteredNotifications.push(e)
                  
                  previous = preFilteredNotifications[preFilteredNotifications.length - 1]
                })
                this.lastId = notifications[notifications.length - 1].id
                previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
                previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
                }
              this.notifications = preFilteredNotifications
            })
          }
      })
    },
    addCommasAndAnd(list) {
      list = list.map(e => { return `<span class="strong">${e}</span>`})
      if (list.length < 3)
        return list.join(' y ')
      if (list.length >= 3){
        let resto = list.length - 2
        list = list.slice(0, 2)
        if(resto == 1)
          list.push(` y a <span class="strong">1</span> persona más`)
        else
          list.push(` y a ${resto} personas más`)
      }
      return `${list.slice(0, - 1).join(', ')}${list[list.length - 1]}`;
    },
    formatNotification(notification){
      let text = notification.from.length > 1 ? notification.message_p : notification.message_s
      let authors = this.addCommasAndAnd(notification.from)
      let title = `<span class="strong">${notification.title}</span>`
      return text.replace('NAME_PLACEHOLDER', authors).replace('POST_PLACEHOLDER', title).replace('EVENT_PLACEHOLDER', title).replace('COMMENT_PLACEHOLDER', title)
    },
    markAsRead(notification)
    {
      if(!notification.seen)
        this.$axios.post("notification/markAsRead", {id: notification.id}).then(res => {
          if(res.status == 200)
            notification.seen = true
          this.$router.push({name: notification.route, params: {id: notification.route_id}})
          this.$refs.dropdown.toggle()
        })
      else{
        this.$router.push({name: notification.route, params: {id: notification.route_id}})
        this.$refs.dropdown.toggle()
      }
      //CREAR OPCION DE MARCAS TODAS LAS VISIBLES COMO LEÍDAS
    },
    
    async onScroll() {
      if(this.lastId != -1 && this.$refs.container.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMoreNotifications && this.notifications.length < this.oldTotal){
        this.loadingMoreNotifications = true
        this.perPage = this.perPageCopy
        
        let previous = null
        this.$axios.post(`notification/getAll/${this.page}/${this.lastId}`).then(res => {
          this.page += 1
          this.newTotal += res.data.total
          let preFilteredNotifications = this.notifications
          const notifications = res.data.notifications
          if(notifications.length > 0){
              previous = notifications[0]
              notifications.shift()
              notifications.forEach((e) => {
                previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
                previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
                if(previous.route == e.route && previous.route_id == e.route_id) {
                  if(!(previous.from.includes(e.from))){
                    previous.from.push(e.from)
                    previous.id.push(e.id)
                  }
                }
                else
                  preFilteredNotifications.push(e)
                
                previous = preFilteredNotifications[preFilteredNotifications.length - 1]
              })
            this.lastId = notifications[notifications.length - 1]?.id || -1
            previous.from = Array.isArray(previous.from) ? previous.from : [previous.from]
            previous.id = Array.isArray(previous.id) ? previous.id : [previous.id]
            }
          this.notifications = preFilteredNotifications
          this.loadingMoreNotifications = false
        })
      }
    },
  },  
  mounted() {
    this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll);
    })
  },
  beforeUnmount() {
    this.$nextTick(() => {
        window.removeEventListener('scroll', this.onScroll);
    })
  }
}
</script>